<template>
  <div>
    <!-- search -->

    <div id="searchDev" :class="windowWidth >= 1200 ? 'searchPosition' : ''">

      <div class="search w-full mt-10">
        <feather-icon icon="ChevronDownIcon" @click="content_visible = !content_visible"
          class="cursor-pointer m-1"></feather-icon>

        <b-card v-if="content_visible">
          <b-row class="w-full ">
            <b-col cols="12" md="3">
              <b-row>
                <b-col cols="12" md="1">
                  <feather-icon icon="CommandIcon" class="cursor-pointer  "></feather-icon>
                </b-col>
                <b-col cols="12" md="10">
                  <v-select :dir="$vs.rtl ? 'rtl' : 'ltr'" style="width: 100%" label="Name" @input="GetSimpleDoctors()"
                    v-model="search.SpecialtyID" :options="specialties" :placeholder="$t('Specialty')"
                    :reduce="(ID) => ID.ID" />
                </b-col>


              </b-row>
            </b-col>

            <b-col cols="12" md="3">
              <b-row>
                <b-col cols="12" md="1">
                  <feather-icon icon="MapPinIcon" class="cursor-pointer"></feather-icon>
                </b-col>
                <!-- countries -->
                <b-col cols="12" md="10">
                  <v-select :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="GetCitiesBycountry()" style="width: 100%" id=""
                    label="Name" v-model="search.CountryID" :options="countries" :reduce="(ID) => ID.ID"
                    :placeholder="$t('Destination')" />
                </b-col></b-row>

            </b-col>

            <b-col cols="12" md="3">
              <b-row>
                <b-col cols="12" md="1">
                  <feather-icon icon="FlagIcon" class="cursor-pointer "></feather-icon>
                </b-col>
                <b-col cols="12" md="10">
                  <v-select :dir="$vs.rtl ? 'rtl' : 'ltr'" style="width: 100%" label="Name" v-model="search.CityID"
                    :options="cities" @input="UpdateSpecialities()" :reduce="(ID) => ID.ID" :placeholder="$t('City')" />
                </b-col>
              </b-row>

            </b-col>

            <b-col cols="12" md="3">
              <b-row>
                <b-col cols="12" md="1">
                  <feather-icon icon="UsersIcon" class="cursor-pointer "></feather-icon>
                </b-col>
                <b-col cols="12" md="10"><v-select :dir="$vs.rtl ? 'rtl' : 'ltr'" style="width: 80%" label="Name"
                    @input="UpdateSpecialities()" v-model="search.DoctorID" :options="doctors" :reduce="(ID) => ID.ID"
                    :placeholder="$t('Doctors')" />
                </b-col>
              </b-row>

            </b-col>

            <b-col cols="12" md="1">
              <div class=" ">
                <b-row>

                  <!-- <b-col
             cols="12"
             md="5">
                    <feather-icon
                      style="padding-top: 10px"
                      class="whitecolor ml-5 mr-5"
                      icon="SearchIcon"
                    />
                  </b-col> -->
                </b-row>
              </div>
            </b-col>


          </b-row>
          <hr>
          <b-row class="w-full ">
            <b-col cols="12" md="1">
              {{ $t('Filter') }}
            </b-col>
            <b-col cols="12" md="2">
              <b-row>
                <b-form-checkbox v-model="search.FilterByRecommended" :vs-value="true" @input="startSearchDoctor" inline>
                  <label for="Recommended">{{ $t('Recommended') }}</label>

                </b-form-checkbox>
              </b-row>

            </b-col>

            <b-col cols="12" md="2">

              <b-row>
                <b-form-checkbox v-model="search.FilterByPatientsReviews" inline>
                  <label for="PatientsReviews">{{ $t('PatientsReviews') }}</label>

                </b-form-checkbox>
              </b-row>

            </b-col>

            <b-col cols="12" md="2">

              <b-form-radio-group v-model="search.FilterByHighstToLowestPrice" :vs-value="true"
                @change="changeFilterPrice(1)" name="Filter"
                :options="[{ text: $t('HighstToLowestPrice'), value: true }]" />

            </b-col>
            <b-col cols="12" md="2">
              <b-form-radio-group v-model="search.FilterByLowestToHighstPrice" :vs-value="true"
                @change="changeFilterPrice(2)" name="Filter"
                :options="[{ text: $t('LowestToHighstPrice'), value: true }]" />

            </b-col>
            <b-col cols="12" md="1">
              <feather-icon @click="ResetFilter" style="width: 20px;height: 20px;" icon="RefreshCwIcon"></feather-icon>

              <!-- <vx-tooltip :text="$t('ResetFilter')" position="bottom" delay=".3s"> 
    </vx-tooltip> -->
            </b-col>
            <b-col cols="12" md="2">
              <b-button @click="startSearchDoctor" variant="primary">
                {{ $t("Search") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>


    <!-- form -->
    <form-wizard ref="refFormWizard" color="#2753D9" :title="null" :subtitle="null" finish-button-text="Submit"
      back-button-text="Previous" hide-buttons class="checkout-form-wizard steps-transparent">
      <!-- ChooseDoctor -->
      <tab-content :title="$t('ChooseDoctor')" icon="feather icon-user">
        <search-doctor-cart @next-step="nextTabSessionDetails" :searchDoctors="searchDoctors" />
        <h1 style="text-align:center" v-if="IsShowMore && searchDoctors && searchDoctors.length > 0"
          @click="getNextDoctors">
          {{ $t('ShowMore') }}</h1>

      </tab-content>
      <!-- SessionDetails -->
      <tab-content :disabled="DisableTap1" :title="$t('SessionDetails')" icon="feather icon-info">
        <session-details-cart @next-step="nextTabSymptom" @Previos-step="PreStep" :sessionModel="sessionModel" />

      </tab-content>
      <!-- PatientSymptoms -->
      <tab-content :title="$t('PatientSymptoms')" icon="feather icon-home">
        <extra-details-cart @next-step="nextTabPayment" :sessionModel="sessionModel"
          :updatedPatientSession="updatedPatientSession" />
      </tab-content>

      <!-- Payment -->
      <tab-content :title="$t('Payment')" icon="feather icon-credit-card">
        <payment-cart @next-step="nextTabPayment" :sessionModel="sessionModel"
          :updatedPatientSession="updatedPatientSession" />
      </tab-content>
    </form-wizard>
    <vs-popup title="" :active.sync="ShowLoginPopup">
      <UserNotLogin :MessageAction="'BookAnOnlineAppointment'" v-if="ShowLoginPopup" @closePop="ShowLoginPopup = false">
      </UserNotLogin>
    </vs-popup>
  </div>
</template>

<script>
import { domain } from "@/gloabelConstant.js"
import moduleDoctor from "@/store/doctor/moduleDoctor.js"
import moduleCity from "@/store/settings/city/moduleCity.js"
import moduleCountry from "@/store/settings/country/moduleCountry.js"
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js"
import UserNotLogin from "@/views/UserNotLogin.vue"
import { ref } from '@vue/composition-api'

import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BForm, BFormCheckbox, BFormCheckboxGroup, BFormGroup,
  BFormInput,
  BFormInvalidFeedback, BFormRadioGroup, BFormTextarea,
  BRow
} from "bootstrap-vue"
import { FacebookIcon } from "vue-feather-icons"
import { FormWizard, TabContent } from 'vue-form-wizard'
import ExtraDetailsCart from './ExtraDetailsCart.vue'
import PaymentCart from './PaymentCart.vue'
import SearchDoctorCart from './SearchDoctorCart.vue'
import SessionDetailsCart from './SessionDetailsCart.vue'
export default {
  components: {
    // 3rd Party
    UserNotLogin,
    FormWizard,
    TabContent,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormTextarea,
    BRow, BFormCheckbox, BFormCheckboxGroup, BFormRadioGroup,
    // SFC
    SessionDetailsCart,
    SearchDoctorCart,
    ExtraDetailsCart,
    PaymentCart,
  },
  data() {
    return {
      ShowLoginPopup: false,
      content_visible: true,
      DisableTap1: false,
      DisableTap2: false,

      search: {
        SpecialtyID: "",
        IsGeneral: false,
        IsHelper: false,
        IsOnline: false,
        CountryID: "",
        CityID: "",
        DoctorID: "",
        IsPaging: true,
        PageNumber: 1,
        PageSize: 10,
        ForAppointment: null,
      },
      baseURL: domain,
      CurrentPage: 1,
      CountPerPage: 4,
      IsShowMore: true,
      sessionModel: {},
      updatedPatientSession: {}
    };
  },
  methods: {
    changeFilterPrice(id) {
      debugger
      if (id == 1) {  //from heigh to low
        this.search.FilterByLowestToHighstPrice = false
        this.startSearchDoctor();
      }
      else {  //from low to heigh
        this.search.FilterByHighstToLowestPrice = false;
        this.startSearchDoctor();
      }
    },
    PreStep() {
      debugger
      this.refFormWizard.prevTab();
    },
    nextTabPayment(session, reservationsession) {
      debugger
      this.sessionModel = session;
      this.updatedPatientSession = reservationsession;
      this.refFormWizard.nextTab();

    },

    nextTabSymptom(session, reservationsession) {
      this.sessionModel = session;
      this.updatedPatientSession = reservationsession;
      this.refFormWizard.nextTab();
      this.DisableTap2 = true;
    },
    nextTabSessionDetails(session, doctor) {
      this.sessionModel = session

      debugger;
      if (
        !this.$store.state.auth.isUserLoggedIn() ||
        this.$store.state.AppActiveUser.Patient.ID == 0
      ) {
        this.ShowLoginPopup = true;

        return false;
      }
      if (session.IsBlocked == true) {
        window.sessionBlock();
      } else if (!session.IsAvailable == true) {
        window.sessionOldSelected();
      } else if (session.IsAvailable == true) {
        debugger;
        this.sessionModel.Paylater = session.Paylater;
        this.sessionModel.SessionID = session.ID;
        this.sessionModel.DoctorID = session.DoctorID;
        this.sessionModel.DoctorName = session.Doctor.Name;

        this.sessionModel.SessionDate = session.DateFrom;
        this.sessionModel.TimeFrom = session.DateFrom.slice(11, 16);
        this.sessionModel.TimeTo = session.DateTo.slice(11, 16);
        this.sessionModel.doctorImg = doctor.ProfileImagePath;
        this.sessionModel.Country = doctor.CountryName;
        this.sessionModel.Specialties = doctor.Specialties;
        this.sessionModel.Educations = doctor.Educations;
        this.sessionModel.MarkupFees = session.MarkupFees;
        this.sessionModel.VAT = session.VAT;
        this.From = new Date(
          "01/01/2007 " + session.DateFrom.slice(11, 16)
        );
        this.To = new Date("01/01/2007 " + session.DateTo.slice(11, 16));

        this.sessionModel.DurationInMinutes = parseInt(
          (Math.abs(
            new Date(this.To.getTime()) - new Date(this.From.getTime())
          ) /
            (1000 * 60)) %
          60
        );
        this.refFormWizard.nextTab()
        this.DisableTap1 = true;

      }

    },

    //     refreshCity(){
    // this.search.CityID = this.search.CityID
    //     },
    UpdateSpecialities() {

      this.$store.dispatch("SpecialtyList/GellAllSpecificSpecialty", this.search);
      this.getAppointmentCountries()
    },
    GetSimpleDoctors() {
      this.$store.dispatch("DoctorList/SimpleSearchDoctors", this.search);
      this.getAppointmentCountries()
    },
    ResetFilter() {
      this.search = {
        SpecialtyID: "",
        CountryID: "",
        CityID: "",
        DoctorID: "",
        IsPaging: true,
        PageNumber: 1,
        PageSize: 10,
        ForAppointment: null,
      }
      this.startSearchDoctor()
    },
    startSearchDoctor() {
      this.search.PageNumber = 1;
      this.IsShowMore = true;
      this.$store.commit("DoctorList/SET_SearchDoctor", []);
      this.searchDoctor();
    },
    getNextDoctors() {
      debugger;
      this.search.PageNumber++;
      this.searchDoctor();
    },
    clearSearch() {
      this.search.SpecialtyID = "";
      this.search.CityID = "";
      this.search.CountryID = "";

      this.search.IsPaging = true;
      this.search.PageNumber = 1;
    },
    setSelectedCity(Id) {
      this.search.CityID = Id;
    },
    GoBack() {
      this.CurrentPage = this.CurrentPage - 1;
    },

    GoNext() {
      this.CurrentPage = this.CurrentPage + 1;
    },
    back() {
      this.$router.go(-1);
    },

    searchDoctor() {
      debugger;
      this.$vs.loading();
      this.$store.dispatch("DoctorList/SearchDoctorsPaging", this.search).then((res) => {
        this.$vs.loading.close();
        debugger;
        debugger;
        if (this.searchDoctors.length == 0) {
          this.$vs.notify({
            title: this.$t("NoData"),
            text: this.$t("NoDataToshow"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning",
          });
        }
        if (res.data.Data.length == 0) {
          this.IsShowMore = false;
        }
        // if (
        //   document.documentElement.scrollHeight <= window.innerHeight + 750 &&
        //   res.data.Data.length >= this.search.PageSize
        // ) {
        //   debugger;
        //   this.search.PageNumber++;
        //   this.searchDoctor();
        // }
      });
    },
    selectDestination(destination) {
      this.search.CountryID = destination.ID;
      this.GetCitiesBycountry();
    },
    selectCity(city) {
      debugger;
      this.search.CityID = city.ID;
    },

    GetCitiesBycountry() {
      debugger
      this.GetSimpleDoctors();
      this.search.CityID = null;
      this.$store.dispatch("CityList/GetAllCitiesByCountryID", this.search.CountryID);
      this.UpdateSpecialities();
    },
    getAppointmentCountries() {
      this.$store.dispatch("CountryList/GetAllAppointmentCountries", this.search);

    }
    //  handleScroll(event) {
    //   console.log(window.scrollY);
    // },

  },
  computed: {

    topDestinationsPage() {
      return this.$store.state.CountryList.topDestinations.slice(
        (this.CurrentPage - 1) * this.CountPerPage,
        (this.CurrentPage - 1) * this.CountPerPage + this.CountPerPage
      );
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    searchDoctors() {
      debugger;
      return this.$store.state.DoctorList.searchDoctors;
    },
    doctors() {
      debugger;
      return this.$store.state.DoctorList.doctors;
    },
    specialties() {
      debugger;
      return this.$store.state.SpecialtyList.specialties;
    },

    doctorCountries() {
      return this.$store.state.CountryList.doctorCountries;
    },
    countries() {
      return this.$store.state.CountryList.countries;
    },
    cities() {
      return this.$store.state.CityList.cities
    },
  },
  created() {
    // window.addEventListener("scroll", this.handleScroll);
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    if (!moduleCity.isRegistered) {
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }

    this.$store.commit("DoctorList/SET_SearchDoctor", []);
    this.$store.commit("DoctorList/SET_Doctor", []);
    debugger;
    const CountryID = this.$route.params.CountryID;
    const SpecialtyID = this.$route.params.SpecialtyID;
    const DoctorID = this.$route.params.DoctorID;
    const IsGeneral = this.$route.params.IsGeneral;
    const IsHelper = this.$route.params.IsHelper;
    const IsOnline = this.$route.params.IsOnline;
    debugger
    // if (IsGeneral != undefined) this.search.IsGeneral = true;
    // if (IsHelper != undefined) this.search.IsHelper = true;
    // if (IsOnline != undefined) this.search.IsOnline = true;

    if (CountryID != undefined || SpecialtyID != undefined || DoctorID != undefined) {
      debugger
      if (SpecialtyID != undefined) this.search.SpecialtyID = parseInt(SpecialtyID);
      if (CountryID != undefined) this.search.CountryID = parseInt(CountryID);
      if (DoctorID != undefined) this.search.DoctorID = parseInt(DoctorID);
      if (IsGeneral != undefined && IsGeneral != '0') {
        this.search.IsGeneral = true;
      }
      else {
        this.search.IsGeneral = false;
      }
      if (IsHelper != undefined && IsHelper != '0') {
        this.search.IsHelper = true;
      }
      else {
        this.search.IsHelper = false;
      }
      if (IsOnline != undefined && IsOnline != '0') {
        this.search.IsOnline = true;
        this.search.ForAppointment = 1;
      }
      else {
        this.search.IsOnline = false;
      }

      this.search.PageNumber = 1;
      this.$store.state.DoctorList.search = this.search;
      this.search;
      debugger
      this.searchDoctor();
    }
    else {
      debugger
      this.search = this.$store.state.DoctorList.search
      if (IsGeneral != undefined && IsGeneral != '0') {
        this.search.IsGeneral = true;
      }
      else {
        this.search.IsGeneral = false;
      }
      if (IsHelper != undefined && IsHelper != '0') {
        this.search.IsHelper = true;
      }
      else {
        this.search.IsHelper = false;
      }
      if (IsOnline != undefined && IsOnline != '0') {
        this.search.IsOnline = true;
        this.search.ForAppointment = 1;
      }
      else {
        this.search.IsOnline = false;
      }
      this.search.PageNumber = 1;
      this.search.PageSize = 10;

      this.search.CityID = null;

      this.search;
      debugger

      this.searchDoctor();
    }
    this.search;
    debugger
    this.UpdateSpecialities();
    // if(!this.$store.state.DoctorList.doctors ||
    //   this.$store.state.DoctorList.doctors.length == 0 )
    this.$store.dispatch("CountryList/GetAllAppointmentCountries", this.search);

    this.$store.dispatch("DoctorList/SimpleSearchDoctors", this.search);
  },

  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }



    return {
      refFormWizard,
      formWizardNextStep,

    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
